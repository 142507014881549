import { graphql, useStaticQuery } from 'gatsby';
import localeFilter from '@lce/intl-util/src/localeFilter';
import { ISiteProps, ILocaleProps } from '@lce/intl-types/src/IDatoLocale';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';

// eslint-disable-next-line @typescript-eslint/no-type-alias
export declare type Direction = 'rtl' | 'ltr';
export interface IDirectionProps {
  direction: Direction;
}

export interface ISiteConfigProps extends ISiteProps {
  siteConfig: {
    nodes: ISiteConfigDefaultProps[];
  };
}

interface ISiteConfigDefaultProps extends ILocaleProps {
  isThreePanel: boolean;
  googleMapKey: string;
  isRtl: boolean;
  message404: string;
  buttonText404: string;
  buttonLink404: string;
  image404: IDatoImage;
}

// eslint-disable-next-line max-lines-per-function

const useSiteConfig = (locale: string) => {
  const data: ISiteConfigProps = useStaticQuery(graphql`
    query SiteConfigQuery {
      datoCmsSite {
        locale
      }
      siteConfig: allDatoCmsSiteConfig {
        nodes {
          isThreePanel
          isRtl
          locale
          googleMapKey
          image404 {
            alt
            url
          }
          message404
          buttonText404
          buttonLink404
        }
      }
    }
  `);

  const filteredSiteConfig = localeFilter<ISiteConfigDefaultProps>(
    data.siteConfig && data.siteConfig.nodes,
    locale,
  );

  const direction: Direction = filteredSiteConfig && filteredSiteConfig.isRtl ? 'rtl' : 'ltr';

  return {
    object404: {
      buttonLink: filteredSiteConfig?.buttonLink404,
      buttonText: filteredSiteConfig?.buttonText404,
      image: filteredSiteConfig?.image404,
      message: filteredSiteConfig?.message404,
    },
    direction,
    siteConfig: {
      ...filteredSiteConfig,
    },
  };
};

export default useSiteConfig;
